
import React from 'react';
import './Pricing.less'
import SemiCircle from '../../images/semicircle_lighblue.png'
import AccountIcon from '../../../base/images/pricing.png';
import { TranslatedTexts } from '../Locale/TranslatedTexts';
class Pricing extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            numAccs: 0,
            numSlots: 0,
            withApp: false            
        };
    }

    getPricePerAcc(numAcc, withApp)
    {
        if (numAcc < 4 && withApp)
        {
            return 25;
        }
        else if (numAcc < 4)
        {
            return 20
        }
        else if (numAcc < 14 && withApp)
        {
            return 23
        }
        else if (numAcc < 14)
        {
            return 18
        }
        else if (numAcc < 29 && withApp)
        {
            return 20
        }
        else if (numAcc < 29)
        {
            return 15
        }
        else if (numAcc < 49 && withApp)
        {
            return 17
        }
        else if (numAcc < 49)
        {
            return 12
        }
        else if (withApp)
        {
            return 15
        }
        else
        {
            return 10
        }
    }

    getPricePerSlot(numSlots, withApp)
    {
        if (numSlots < 4 && withApp)
        {
            return 45;
        }
        else if (numSlots < 4)
        {
            return 40
        }
        else if (numSlots < 19 && withApp)
        {
            return 30
        }
        else if (numSlots < 19)
        {
            return 25
        }
        else if ( withApp)
        {
            return 25
        }
        else
        {
            return 20
        }
    }

    updateWithApp(newVal)
    {
        this.setState({withApp: newVal.target.checked});
    }

    updateSlots(newVal)
    {
        this.setState({numSlots: Number(newVal.target.value)});
    }

    updateAccs(newVal)
    {        
        this.setState({numAccs: Number(newVal.target.value)})
    }    

    render() {
        return (
            <div className='main_page pricingInformation'>
                <div className='user_files_container'>
                    <div className='user_files_list_header'>
                        <div className='user_files_list_header_left'>                        
                            <label id='Headline'>Vithrough Pricing</label>
                        </div>
                    </div>
                    <div className='pricing'>                            
                        <div className="container__body">
                            <img src={SemiCircle} className='container__decor'/>                            
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{width: "50%", minWidth: "410px"}}>
                                    <div style={{width: "20%", margin: "5vh 40% 5vh 40%"}}>
                                        <img style={{width: "100%", zIndex: "1"}} src={AccountIcon}/>                                        
                                    </div>
                                    <div style={{width: "60%", margin: "auto", minWidth: "390px"}}>
                                    <input
                                        disabled
                                        style = {{width: "60%", margin: "0 0 0 10%"}}
                                        type="password"
                                        id="password"
                                        name="password"                        
                                        placeholder= "Anzahl zusätzlicher Accounts"
                                    />
                                    <input
                                        style = {{width: "10%", margin: "0 0 0 0%"}}
                                        type="number"
                                        id="accs"
                                        name="accs"
                                        onChange={(e) => this.updateAccs(e)}
                                        value={this.state.numAccs}
                                    />                                    

                                    <input
                                        disabled
                                        style = {{width: "60%", margin: "5% 0 0 10%"}}
                                        type="password"
                                        id="password2"
                                        name="password"                        
                                        placeholder= "Anzahl zusätzlicher Plätze"
                                    />
                                    <input
                                        style = {{width: "10%", margin: "0 0 0 0%"}}
                                        type="number"
                                        id="slot"
                                        name="slot"
                                        onChange={(e) => this.updateSlots(e)}
                                        value={this.state.numSlots}                             
                                    />      
                                    <div style={{height: "14px", margin: "2vh 10%", display: "flex", flexDirection: "row"}}>
                                        <div style={{marginRight: "5%"}}>
                                            <p style={{display: "inline-block", fontSize: "14px", textAlign: "center"}}>Mit App</p>
                                        </div>
                                        <div style={{margin: "3px 0"}}>
                                            <label className="switch">
                                                <input checked={this.state.withApp} onChange={(e) => this.updateWithApp(e)} type="checkbox"></input>
                                                <span className={"slider round"}></span>
                                            </label>
                                        </div>
                                        {
                                            //onChange={(e) => this.updateModelDataCheckbox(e, "Animate")}
                                        }
                                    </div>

                                    <div style={{height: "4px", zIndex: "4", display: "block", position: "relative", width: "100%", backgroundColor: "#6B9797", margin: "25px 0 25px 0"}}></div>
                                        <p style={{display: "inline-block", fontSize: "18px", fontWeight: "600"}}>{this.state.numAccs + 1}</p>
                                        <p style={{display: "inline-block", fontSize: "14px", fontWeight: "400"}}> Accounts + </p>
                                        <p style={{display: "inline-block", fontSize: "18px", fontWeight: "600"}}>{this.state.numSlots + 2}</p>
                                        <p style={{display: "inline-block", fontSize: "14px", fontWeight: "400"}}> Modellplätze  = </p>
                                        <p style={{display: "inline-block", fontSize: "30px", fontWeight: "600"}}>{this.getPricePerAcc(this.state.numAccs, this.state.withApp) * this.state.numAccs + this.getPricePerSlot(this.state.numSlots, this.state.withApp) * this.state.numSlots + (this.state.withApp? 140 : 120)} €</p>
                                        <p style={{display: "inline-block", fontSize: "14px", fontWeight: "400", margin: "0 0 5vh 0 "}}>/ mtl. (netto)</p>
                                    </div>
                                    
                                    
                                </div>
                                <div className='rightdiv' style={{marginTop: "5vh"}}>
                                    <p style={{fontSize: "18px", fontWeight: "600", padding: "7px 0 25px 0"}}>monatliche Lizenz</p>
                                    <br/>
                                    <p style={{display: "inline-block", fontSize: "12px", fontWeight: "400"}}>ab </p>
                                    <p className='bigPrice'>120 €</p>
                                    <br/>
                                    <p style={{fontSize: "14px", fontWeight: "400", padding: "25px 0 7px 0"}}>1 Account</p>
                                    <p style={{fontSize: "14px", fontWeight: "400", padding: "7px 0"}}>2 Modellplätze</p>
                                    <p style={{fontSize: "14px", fontWeight: "400", padding: "7px 0"}}>Onboarding</p>
                                    <p style={{fontSize: "14px", fontWeight: "400", padding: "7px 0"}}>Support</p>
                                    <br/>
                                    <p style={{fontSize: "18px", fontWeight: "600", margin: "25px 0 7px 0"}}>Optional mit App</p>
                                    <p style={{fontSize: "14px", fontWeight: "400", padding: "7px 0"}}>+ Kompatibel mit Android und iOS</p>
                                    <p style={{fontSize: "14px", fontWeight: "400", padding: "7px 0"}}>+ Offline Modus</p>
                                    <p style={{fontSize: "14px", fontWeight: "400", padding: "7px 0", margin: "0 0 5vh 0 "}}>+ maximale Flexibilität</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}
export default Pricing;
