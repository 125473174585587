import axios from 'axios';
import UserService from '../../../base/components/Keycloak/UserService';
import Dialog from '../Dialog/Dialog';
import { TranslatedTexts } from "../Locale/TranslatedTexts";

export const AdminHandler = {
    getAllUsers: function(userId){
        return new Promise(function(resolve, reject){

            if(!userId){
                reject();
            }
    
            axios.get(window.config.vendor.api + "/modelviewer/getAllUsers?userId=" + userId)
            .then(function (response) {
                const data = response.data;
                if(data.Success){
                    resolve(data);
                }else{
                    reject(data);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
        })},
    getAllCompanies: function(userId){
        return new Promise(function(resolve, reject){
        if(!userId){
            reject();
        }
        
        axios.get(window.config.vendor.api + "/modelviewer/getCompanies?userId=" + userId)
            .then(function (response) {
                const data = response.data;
                if(data.Success){
                    resolve(data);
                }else{
                    reject(data);
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            })
        })},   
        
    addCompany: function(userId, companyName, modelLimit, userLimit, appAccess = false, rapid = false){
        return new Promise(function(resolve, reject){

        if(!userId || !companyName || !modelLimit || !userLimit){
            reject();
        }
        
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("companyName", companyName);
        formData.append("modelLimit", modelLimit);
        formData.append("userLimit", userLimit);
        formData.append("appAccess", appAccess ? 1 : 0);
        formData.append("rapid", rapid ? 1 : 0);              
    
        // Request made to the backend api
        // Send formData object
        axios.post(window.config.vendor.api + "/modelviewer/addCompany", formData)
        .then(function (response) {                
            const data = response.data;
            if(data.Success){
                resolve(data);
            }else{
                reject(data);
            }
        })
        .catch((err) => {
            reject(err);
        })
    })},

        remCompany: function(userId, companyGuid){
            return new Promise(function(resolve, reject){
    
            if(!userId || !companyGuid){
                reject();
            }
            
            const formData = new FormData();
            formData.append("userId", userId);
            formData.append("companyGuid", companyGuid);
                        
        
            // Request made to the backend api
            // Send formData object
            axios.post(window.config.vendor.api + "/modelviewer/remCompany", formData)
            .then(function (response) {                
                const data = response.data;
                if(data.Success){
                    resolve(data);
                }else{
                    reject(data);
                }
            })
            .catch((err) => {
                reject(err);
            })
        })},

        updateUserRole: function(NewRole, UserEmail){
            return new Promise(function(resolve, reject){
    
            if(!UserEmail || !NewRole){
                reject();
            }
            
            const formData = new FormData();
            formData.append("UserEmail", UserEmail);
            formData.append("UserRole", NewRole);
                        
        
            // Request made to the backend api
            // Send formData object
            axios.post(window.config.vendor.api + "/modelviewer/updateUserRole", formData)
            .then(function (response) {                
                const data = response.data;
                if(data.Success){
                    resolve(data);
                }else{
                    reject(data);
                }
            })
            .catch((err) => {
                reject(err);
            })
        })},

        addUser: function(userName, UserEmail, UserLocale, UserRole, CompanyGuid, CompanyName){
            return new Promise(function(resolve, reject){                
                var userId = UserService.SessionData.currentUser.id;
            if(!userId || !userName || !UserEmail|| !CompanyGuid){
                reject();
            }
            UserService.AdminData.createNewUser(UserEmail, "!!", UserEmail, userName, UserLocale, UserRole, true, CompanyGuid, CompanyName, false )
                .then((res) =>{
                    const formData = new FormData();                    
                    formData.append("instigatorId", "userid");
                    formData.append("userId", res.id);
                    formData.append("userName", userName);
                    formData.append("userEmail", UserEmail);
                    formData.append("userLocale", UserLocale);
                    formData.append("companyGuid", CompanyGuid);
                    formData.append("userRole", UserRole);
        
                    // Request made to the backend api
                    // Send formData object
                    axios.post(window.config.vendor.api + "/modelviewer/addUser", formData)
                        .then(function (response) {                
                            const data = response.data;
                            if(data.Success){
                                resolve(data);
                            }
                            else{
                                reject(data);
                                }
                            })
                            .catch((err) => {
                                reject(err);
                            })
                        })
                        .catch((err) =>  {  
                            console.error(err);
                            Dialog.Alert({
                                title: TranslatedTexts.LoadError,
                                description: TranslatedTexts.EMailError,
                                options: [
                                    {label: "OK", className:""},
                                ],
                                panelOptions: {className: "messebau oneButtonDialog"}
                            })
                    })                                
            })},
            remUser: function(adminuser, userId){
                return new Promise(function(resolve, reject){
        
                if(!userId){
                    reject();
                }
                const messageData = new FormData();
                messageData.append("UserID", userId);
                messageData.append("AdminUserID", adminuser);
                axios.post(
                    window.config.host.api + "/modelviewer/removeUser", messageData
                    ).then((res) => {
                        if (res.data.Success) {
                            UserService.AdminData.deleteUser(userId)
                            .then((reso) =>{
                                resolve(res);
                            })
                            .catch((err) => {
                                reject(err);
                            })
                        }
                        else {
                            reject(res.data);
                        }                                         
                    }).catch((e) => {
                        console.error("Failed to remove user.", e)
                        reject(e)
                })
            })},
            updateCompanyLimit: function(companyId, newLimit){
                return new Promise(function(resolve, reject){
        
                if(!companyId || !newLimit){
                    reject("unset values");
                }
                const messageData = new FormData();
                messageData.append("CompanyGuid", companyId);
                messageData.append("ModelLimit", newLimit);
                axios.post(
                    window.config.host.api + "/modelviewer/updateCompanyLimit", messageData
                    ).then((res) => {
                        if (res.data.Success) {                           
                            resolve(res);

                        }
                        else {
                            reject(res.data);
                        }                                         
                    }).catch((e) => {                        
                        reject(e)
                })        
            })},
            updateUserLimit: function(companyId, newLimit){
                return new Promise(function(resolve, reject){
        
                if(!companyId || !newLimit){
                    reject("unset values");
                }
                const messageData = new FormData();
                messageData.append("CompanyGuid", companyId);
                messageData.append("UserLimit", newLimit);
                axios.post(
                    window.config.host.api + "/modelviewer/updateUserLimit", messageData
                    ).then((res) => {
                        if (res.data.Success) {                           
                            resolve(res);
                        }
                        else {
                            reject(res.data);
                        }                                         
                    }).catch((e) => {                        
                        reject(e)
                })        
            })},
            updateCompanyAppAccess: function(companyId, appaccess){
                return new Promise(function(resolve, reject){
        
                if(!companyId){
                    reject("unset values");
                }
                const messageData = new FormData();
                messageData.append("CompanyGuid", companyId);
                messageData.append("AppAccess", appaccess ? "1" : "0");
                axios.post(
                    window.config.host.api + "/modelviewer/updateCompanyAppAccess", messageData
                    ).then((res) => {
                        if (res.data.Success) {                           
                            resolve(res);

                        }
                        else {
                            reject(res.data);
                        }                                         
                    }).catch((e) => {                        
                        reject(e)
                })        
            })},
            updateCompanyRapidAccess: function(companyId, rapidAccess){
                return new Promise(function(resolve, reject){
        
                if(!companyId){
                    reject("unset values");
                }
                const messageData = new FormData();
                messageData.append("CompanyGuid", companyId);
                messageData.append("RapidAccess", rapidAccess ? "1" : "0");
                axios.post(
                    window.config.host.api + "/modelviewer/updateCompanyRapidAccess", messageData
                    ).then((res) => {
                        if (res.data.Success) {                           
                            resolve(res);

                        }
                        else {
                            reject(res.data);
                        }                                         
                    }).catch((e) => {                        
                        reject(e)
                })        
            })},
                 
}